let segmentAnonymousId: string

window.analytics &&
  analytics.ready(() => {
    segmentAnonymousId = analytics.user().anonymousId()
  })

export const getSegmentAnonymousId = () => {
  if (!segmentAnonymousId) {
    return 'missing-anonymous-id'
  }
  return segmentAnonymousId
}

export const setSegmentAnonymousId = (id: string) => {
  window.analytics &&
    analytics.ready(() => {
      analytics.setAnonymousId(id)
      segmentAnonymousId = analytics.user().anonymousId()
    })
}

export const redirectWithSegmentParam = (url: string) => {
  const querySymbol = url.includes('?') ? '&' : '?'
  const resolvedUrl = `${url}${querySymbol}segment_anonymous_id=${encodeURIComponent(
    getSegmentAnonymousId()
  )}`
  window.location.href = resolvedUrl
}

export const addSegmentAnonymousIdToUrl = (url: string) => {
  const querySymbol = url.includes('?') ? '&' : '?'
  return `${url}${querySymbol}segment_anonymous_id=${encodeURIComponent(
    getSegmentAnonymousId()
  )}`
}

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import React from 'react'

import Carousel from './Carousel'
import Item from './Item'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: '27px',
    fontWeight: 700
  }
}))

export const meds = [
  { name: 'Buspirone', brandName: '(Buspar)' },
  { name: 'Fluoxetine', brandName: '(Prozac)' },
  { name: 'Escitalopram', brandName: '(Lexapro)' },
  { name: 'Duloxetine', brandName: '(Cymbalta)' },
  { name: 'Venlafaxine', brandName: '(Effexor)' },
  { name: 'Citalopram', brandName: '(Celexa)' },
  { name: 'Bupropion', brandName: '(Wellbutrin)' },
  { name: 'Sertraline', brandName: '(Zoloft)' }
]

export default function AvailableMeds () {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <div role='complementary'>
      <Box color='common.white' pb={3}>
        <Typography align='center' variant='button' component='p'>MEDICATION</Typography>
        <Typography className={classes.header} align='center' variant='body1'>What we prescribe</Typography>
      </Box>
      {isMobile
        ? (
          <Carousel />
        )
        : (
          <Grid container spacing={2}>
            {meds.map(({ name, brandName }) => (
              <Grid item xs={3} key={name}>
                <Item {...{ name, brandName }} />
              </Grid>
            ))}
          </Grid>
        )}
      <Box display='flex' justifyContent='center' pt={3}>
        <Button color='secondary' variant='contained' target='_blank' rel='noreferrer' href='https://khealth.com/anxiety-depression'>Learn more</Button>
      </Box>
    </div>
  )
}

import { Link, Typography } from '@material-ui/core'
import image from 'Images/kids-lying-down.svg'
import DefaultPageContainer from 'Offerings/shared/DefaultPageContainer'
import React from 'react'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import { oneTimeVisit } from '../../offerings/constants/externalLinks'
import FeatureList from '../shared/FeatureList'

const features = [
  'Unlimited text-based visits with board-certified clinicians',
  'Personalized care plans for chronic, preventative, mental, and general health',
  'Easy medication management',
  'No appointment, no insurance, no problem'
]

export default function OverEighteen () {
  return (
    <DefaultPageContainer image={image} imageAltText='Kids lying down on grass' role='main'>
      <Typography aria-label='Child over 18 needs to create their own account' aria-level={1} role='heading' variant='h3'>
        If your child is over 18 they need to create their own account
      </Typography>
      <Typography variant='body1'>
        A K Health membership includes:
      </Typography>

      <FeatureList features={features} />
      <ResponsiveCTAButton
        component={Link}
        context='body'
        ctaAction={'18+ Child Pricing Options Viewed'}
        href={addSegmentAnonymousIdToUrl(oneTimeVisit)}
      >
        See pricing options
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

import { Box, Grid, makeStyles } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import desktopImg from 'Images/lisinopril.svg'
import mobileImg from 'Images/lisinopril-2.svg'
import React from 'react'

const useStyles = makeStyles(({ breakpoints, spacing }) => {
  return ({
    root: {
      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse'
      }
    },
    textContainer: {
      alignSelf: 'center',
      [breakpoints.up('md')]: {
        alignSelf: 'center',
        bottom: 700
      },
      [breakpoints.down('sm')]: {
        padding: `${spacing(3)}px ${spacing(2)}px 0 ${spacing(2)}px`
      }
    },
    legitScript: {
      [breakpoints.up('md')]: {
        bottom: spacing(8),
        right: spacing(8)
      },
      [breakpoints.down('sm')]: {
        top: spacing(6),
        left: spacing(4)
      }
    },
    imageBox: {
      maxHeight: '600px',
      marginTop: spacing(4)
    }
  })
})

type PageContainerProps = {
  children: React.ReactNode,
  mobileImgOnBottom?: boolean,
  role?: string,
}

export default function PageContainer ({ children, mobileImgOnBottom = false, role = 'main' }: PageContainerProps) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const image = isMobile ? mobileImg : desktopImg

  return (
    <Grid container justifyContent='flex-end' alignContent='center' alignItems='center'>
      <Grid item xs={12} md={5}>
        <Box className={classes.textContainer} role={role}>
          {children}
        </Box>
      </Grid>
      <Grid item xs={12} md={6} component={Box} position='relative'>
        {/* @ts-ignore */}
        <Box className={classes.imageBox} alt='Container of Lisinopril pills' component='img' src={image} width={1} />
        <a href='http://legitscript.com/pharmacy/khealth.com' target='_blank' title='Verify LegitScript Approval' rel='noreferrer'>
          {/* @ts-ignore */}
          <Box width={100} className={classes.legitScript} alt='LegitScript seal of approval' position='absolute' component='img' src='http://static.legitscript.com/seals/9571275.png' />
        </a>
      </Grid>
    </Grid>
  )
}

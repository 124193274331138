import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '35px',
    alignSelf: 'start'
  },
  text: {
    marginTop: 'unset'
  }
}))

type FeatureListProps = {
  features: string[]
}
export default function FeatureList ({ features }: FeatureListProps) {
  const classes = useStyles()
  return (
    <List dense role='list'>
      {features.map((feature) => (
        <ListItem key={feature} disableGutters>
          <ListItemIcon className={classes.icon}><CheckCircleIcon color='secondary' /></ListItemIcon>
          <ListItemText className={classes.text} primary={feature} role='complementary' />
        </ListItem>
      ))}
    </List>
  )
}

import { Link, Typography } from '@material-ui/core'
import image from 'Images/woman-laughing-with-girl.svg'
import DefaultPageContainer from 'Offerings/shared/DefaultPageContainer'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
// TODO: Swap out URL once AB test is complete
import { healthDialogue } from '../../offerings/constants/externalLinks'
import FeatureList from '../shared/FeatureList'

const features = [
  'No appointment, no insurance, no problem',
  'Treatment for hundreds of different conditions',
  'Prescription renewals and refills'
]

export default function UrgentCare () {
  const kidsCareStr = 'Care for kids ages 3-17'
  const careFeatures = [...features, kidsCareStr]

  return (
    <DefaultPageContainer image={image} imageAltText='Mother hugging child'>
      <Typography aria-label='Text with a board-certified doctor' aria-level={1} paragraph variant='h4' role='heading'>
        Text with a board-certified doctor 24/7
      </Typography>

      <FeatureList features={careFeatures} />
      <ResponsiveCTAButton
        component={Link}
        context='body'
        ctaAction='HD Flow Started'
        href={addSegmentAnonymousIdToUrl(healthDialogue)}
      >
        Get started
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

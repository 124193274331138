import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import GoodReviews from 'Icons/good-reviews.svg'
import ShieldSteth from 'Icons/shield-stethoscope.svg'
import UnitedStates from 'Icons/united-states.svg'
import React from 'react'

const certs = [
  { icon: ShieldSteth, text: 'BOARD-CERTIFIED DOCTORS' },
  { icon: GoodReviews, text: '5M+ USERS\n40K+REVIEWS' },
  { icon: UnitedStates, text: 'AVAILABLE 24/7 IN 48 STATES' }
]

const mobileHeight = 112
const desktopHeight = 132

const useStyles = makeStyles(({ breakpoints, spacing, typography: { body1, subtitle2 } }) => ({
  container: {
    height: desktopHeight,
    position: 'absolute',
    top: -(desktopHeight / 2),
    padding: `${spacing(2)}px ${spacing(1)}px`,
    [breakpoints.down('xs')]: {
      height: mobileHeight,
      padding: `${spacing(0.5)}px`,
      top: -(mobileHeight / 2)
    },
    [breakpoints.down('sm')]: {
      borderRadius: '0px 0px 0px 20px'
    },
    [breakpoints.up('md')]: {
      borderRadius: '0px 50px 50px 50px',
      marginRight: spacing(2)
    }
  },
  certification: {
    [breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      margin: spacing(3)
    }
  },
  icon: {
    [breakpoints.down('xs')]: {
      height: '26px'
    }
  },
  text: {
    ...body1,
    [breakpoints.down('xs')]: {
      ...subtitle2
    }
  }
}))

export default function CertificationsBar () {
  const classes = useStyles()

  return (
    <Grid container justifyContent='flex-end'>
      <Grid item xs={11} md={8}>
        <Box className={classes.container} bgcolor='#E8F5FF' display='flex' justifyContent='space-around' role='complementary'>
          {certs.map(({ icon, text }, index) => {
            const textSplit = text.split('\n')

            return (
              <Box key={index} p={1} className={classes.certification} textAlign='center'>
                {/* @ts-ignore */}
                <Box className={classes.icon} px={1} component='img' alt='certification' src={icon} />
                <Box display='flex' flexDirection='column'>
                  {textSplit.map((text, index) => (
                    <Typography
                      className={classes.text}
                      key={`certification-reviews-text-${index}`}
                    >
                      <strong>{text}</strong>
                    </Typography>
                  ))}
                </Box>
              </Box>
            )
          })}
        </Box>
      </Grid>
    </Grid>
  )
}

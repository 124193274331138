import { Typography } from '@material-ui/core'
import { newPrescriptionPath } from 'Offerings/pages'
import FancyAnswerSelect from 'Questions/shared/AnswerSelect/Fancy'
import { useHistory } from 'react-router'
import { useQuestionTracking } from 'Src/tracking'

import ResponsiveContainer from '../shared/ResponsiveContainer'

enum Value {
  New = 'Get a new prescription',
  // TransferValue = 'Transfer a prescription'
}

// const transferValue = 'Transfer a prescription'

const optionsProps = [
  { value: Value.New, subtext: 'Chat with a clinician about a new or ongoing condition' }
  // { value: transferValue, subtext: 'Save time and money when you transfer to our Pharmacy' },
]

const options = optionsProps.map(o => o.value)
const question = 'What are you looking to do?'

const { New } = Value

export default function HelpWithMedication () {
  const pathMap = {
    [New]: newPrescriptionPath
    // [transferValue]: transferPrescriptionPath,
  }

  const trackAnswered = useQuestionTracking({ question, options })
  const history = useHistory()

  function handleSelect (answer: Value) {
    trackAnswered(answer)
    history.push(pathMap[answer])
  }

  return (
    <ResponsiveContainer>
      <Typography variant='h4'>{question}</Typography>
      {optionsProps.map((o) => <FancyAnswerSelect<Value> key={o.value} {...o} onSelect={handleSelect} />)}
    </ResponsiveContainer>
  )
}

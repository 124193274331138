import { Link } from '@material-ui/core'
import { ReactComponent as GoogleButton } from 'Images/google-play-button.svg'
import { download } from 'Offerings/constants/externalLinks'
import React from 'react'

const Google = () => {
  return (
    <Link href={download}>
      <GoogleButton />
    </Link>
  )
}

export default Google

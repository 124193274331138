import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export type CtaContextType = 'header' | 'sidebar' | 'footer' | 'body' | 'modal'

export type CtaActionType =
  'Monthly Pricing Plan Selected' |
  'OneTime Pricing Plan Selected' |
  '18+ Child Pricing Options Viewed' |
  'HD Flow Started' |
  'Flow Started' |
  'Health Guides Viewed' |
  'How It Works Video Viewed' |
  'KHealth Medical Team Info Viewed' |
  'Medication Search Started' |
  'Membership Plans Viewed' |
  'Mental Health Assessment Started' |
  'Mental Health Landing Page Viewed' |
  'New Prescription Pricing Options Viewed' |
  'Pediatrics (3-17) Pricing Options Viewed' |
  'Prescription Renewal Pricing Options Viewed' |
  'Primary Care Pricing Options Viewed' |
  'SMS Download Link Sent' |
  'Under Three Child Pricing Options Viewed' |
  'Urgent Care Pricing Options Viewed'

export type ExperimentVariant = 'control' | 'new-intent-routing'

type CtaParams = {
  action: CtaActionType,
  context: CtaContextType
}

type QuestionParams = {
  question: string,
  options: string[]
}

export const useQuestionTracking = ({ question, options }: QuestionParams) => {
  useEffect(() => {
    analytics.track('Survey Question Viewed', {
      question,
      answer_options: options
    })
  }, [question, options])

  return (answer: string) => {
    analytics.track('Survey Question Answered', {
      question,
      answer_options: options,
      answer
    })
  }
}

export const useCTAClickTracking = () => {
  const { pathname } = useLocation()

  return ({ action, context }: CtaParams) => {
    analytics.track('Cta Clicked', {
      action,
      cta_context: context,
      page_url: pathname
    })
  }
}

export const trackCareNavigationStarted = () => {
  analytics.track('Care Navigation Started')
}

export const trackExperimentStarted = (variant: ExperimentVariant) => {
  analytics.identify({ experiment_care_nav_intent_nov2021: variant })

  analytics.track('Experiment Viewed', {
    experiment_id: 'experiment_care_nav_intent_nov2021',
    variant
  })
}

import { Dialog } from '@material-ui/core'

type HowItWorksProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
}

const howItWorksVideoUrl = 'https://www.youtube.com/embed/D1FSEql8E8Q'

export default function HowItWorksDialog ({ isDialogOpen, setIsDialogOpen }: HowItWorksProps) {
  return (
    <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
      <iframe
        width='560'
        height='315'
        src={howItWorksVideoUrl}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </Dialog>
  )
}

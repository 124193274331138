import { Box, Container, Grid, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import React from 'react'
import { useLocation } from 'react-router'

import ProductCard from './ProductCard'
import ProductGrid from './ProductGrid'

interface locationState {
  state: { fromPrimaryCare: true } | undefined
}

export enum Plans {
  Monthly = 'monthly',
  OneTime = 'oneTime'
}

const baseFeatures = [
  '24/7 access to board-certified clinicians',
  'Prescriptions as needed'
]

export const allFeatures = [
  ...baseFeatures,
  'Unlimited virtual visits'
]

export const featureMap = {
  [Plans.OneTime]: baseFeatures,
  [Plans.Monthly]: allFeatures
}

export default function SelectProduct () {
  const { OneTime, Monthly } = Plans
  const { state }: locationState = useLocation()
  const showOneTime = !state?.fromPrimaryCare

  const primaryCarePlans = [Monthly]

  const isMobile = useIsMobile()
  return (
    <Container component={Box} py={4} role='main'>
      <Box textAlign='center' pb={4}>
        <Typography variant='h4'>Select a plan to get started</Typography>
        <Typography paragraph variant='body1'>For individuals 18 years old and up</Typography>
      </Box>
      {isMobile
        ? (
          <Grid container direction='column' spacing={1}>
            {(showOneTime ? [Monthly, OneTime] : [Monthly]).map((plan) => (
              <Grid item key={plan}>
                <ProductCard plan={plan} />
              </Grid>
            ))}
          </Grid>
        )
        : (
          <ProductGrid
            plans={showOneTime ? [OneTime, Monthly] : primaryCarePlans}
          />
        )}

      <Typography component={Box} pt={3} align='center' variant='body1'>
        Cancel anytime for free. This is not insurance.
      </Typography>
    </Container>
  )
}

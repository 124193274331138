import { makeStyles, TableCell } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'

const useStyles = makeStyles(({ palette: { text, divider } }) => ({
  root: {
    border: `1px ${divider} solid`
  },
  includesFeature: {
    color: '#6FCF97'
  },
  excludesFeature: {
    color: '#4D7088'
  }
}))

type FeatureCheckCellProps = {
  hasFeature?: boolean
}

export default function FeatureCheckCell ({ hasFeature = true }: FeatureCheckCellProps) {
  const classes = useStyles()

  return (
    <TableCell align='center' className={classes.root}>
      {hasFeature
        ? (
          <CheckCircleIcon className={classes.includesFeature} fontSize='large' />
        )
        : (
          <CancelIcon className={classes.excludesFeature} fontSize='large' />
        )}
    </TableCell>
  )
}

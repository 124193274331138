import {
  Box,
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import ArrowLeftBlue from 'Icons/arrow-left-blue.svg'
import ArrowRightBlue from 'Icons/arrow-right-blue.svg'
import BrightOdeiHeadshot from 'Images/doctors/Headshot-Bright-Odei.jpg'
import ChrisBodleHeadshot from 'Images/doctors/Headshot-chris-bodle.jpg'
import DavidMorleyHeadshot from 'Images/doctors/Headshot-david-morley.jpg'
import FatimaHussainHeadshot from 'Images/doctors/Headshot-fatima-hussain.jpg'
import FrancisGoldshmidHeadshot from 'Images/doctors/Headshot-francis-goldshmid.jpg'
import HemphillHeadshot from 'Images/doctors/Headshot-hemphill.jpg'
import JonathanHindsHeadshot from 'Images/doctors/Headshot-jonathan-hinds.jpg'
import MiralPatelHeadshot from 'Images/doctors/Headshot-Miral-Patel.jpg'
import RobinFullerChristenson from 'Images/doctors/Headshot-Robyn-Fuller-Christenson.jpeg'
import EllipsesImage from 'Images/ellipses.svg'
import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { virtualize } from 'react-swipeable-views-utils'
import useIsMobile from 'Src/hooks/useIsMobile'
import { useCTAClickTracking } from 'Src/tracking'

const VirtualizeSwipeableViews: any = virtualize(SwipeableViews)

const useStyles = makeStyles(({ breakpoints, spacing, zIndex }) => ({
  bulletIcon: {
    color: '#2B8FFF',
    maxHeight: '12px'
  },
  carousel: {
    padding: 0,
    margin: 0
  },
  careTeamButton: {
    border: '2px solid #2B8FFF',
    fontWeight: 600,
    letterSpacing: spacing(0.2),
    padding: spacing(3),
    [breakpoints.down('sm')]: {
      margin: 'auto 0'
    }
  },
  container: {
    maxWidth: '85%',
    margin: '0 auto',
    padding: spacing(8),
    [breakpoints.down('sm')]: {
      maxWidth: '95%',
      padding: spacing(4)
    }
  },
  doctorImage: {
    cursor: 'pointer',
    borderRadius: '50% 50% 0',
    maxWidth: '75%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  doctorPosition: {
    marginTop: spacing(4),
    textAlign: 'center',
    fontSize: '17px',
    fontWeight: 700
  },
  feature: {
    margin: spacing(2)
  },
  featuresGrid: {
    [breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  iconLeft: {
    cursor: 'pointer',
    left: '15%',
    position: 'relative',
    zIndex: zIndex.drawer,
    [breakpoints.down('sm')]: {
      left: '10%'
    }
  },
  iconRight: {
    cursor: 'pointer',
    position: 'relative',
    right: '15%',
    zIndex: zIndex.drawer,
    [breakpoints.down('sm')]: {
      right: '10%'
    }
  },
  iconContainer: {
    display: 'contents'
  },
  imageBox: {
    backgroundImage: `url(${EllipsesImage})`,
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('sm')]: {
      backgroundImage: 'none'
    }
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  }
}))

const doctors = [
  { image: HemphillHeadshot, position: 'Irmanie Hemphill, MD', url: 'https://khealth.com/doctors/irmanie-hemphill-md/' },
  { image: DavidMorleyHeadshot, position: 'David Morley, MD', url: 'https://khealth.com/doctors/dr-david-morley-md/' },
  { image: RobinFullerChristenson, position: 'Robyn Fuller-Christenson, NP', url: 'https://khealth.com/doctors/robyn-fuller-christenson-nurse-practitioner/' },
  { image: ChrisBodleHeadshot, position: 'Chris Bodle, MD', url: 'https://khealth.com/doctors/dr-chris-bodle-md/' },
  { image: FatimaHussainHeadshot, position: 'Fatima Hussain, MD', url: 'https://khealth.com/doctors/fatima-hussain-md/' },
  { image: FrancisGoldshmidHeadshot, position: 'Francis Goldshmid, MD', url: 'https://khealth.com/doctors/francis-goldshmid-md/' },
  { image: BrightOdeiHeadshot, position: 'Bright Odei, MD', url: 'https://khealth.com/doctors/bright-odei-md/' },
  { image: JonathanHindsHeadshot, position: 'Jonathan Hinds, MD', url: 'https://khealth.com/doctors/dr-jonathan-hinds-md/' },
  { image: MiralPatelHeadshot, position: 'Miral Patel, MD', url: 'https://khealth.com/doctors/miral-patel-md/' }
]

const features = [
  'Board-certified clinicians with training from top-tier institutions',
  'Available 24/7 to provide top-quality, personalized care whenever you need it',
  'Multi-disciplinary leadership team focuses on improving healthcare for all'
]

export default function MedicalTeam () {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const trackCTAClicked = useCTAClickTracking()

  const [currentSlide, setCurrentSlide] = useState(0)

  const numberOfSlides = 9

  const handleCareTeamBtnClick = () => {
    trackCTAClicked({
      action: 'KHealth Medical Team Info Viewed',
      context: 'body'
    })

    window.location.href = 'https://khealth.com/medical-team'
  }

  const handleChangeSlide = (index: number) => {
    const newIndex = mod(index, numberOfSlides)

    setCurrentSlide(newIndex)
  }

  const handleDoctorImgClick = (url: string) => {
    window.location.href = url
  }

  const slideRenderer = ({ key, index }: { key: number, index: number }) => {
    // allows for looping
    const slideIndex = mod(index, numberOfSlides)

    const doctor = doctors[slideIndex]
    const { image, position, url } = doctor
    const altText = `Portrait of ${position}`

    return (
      <Box key={key} p={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        {/* @ts-ignore */}
        <Box alt={altText} className={classes.doctorImage} component='img' onClick={() => handleDoctorImgClick(url)} src={image} />
        <Typography className={classes.doctorPosition}>
          {position}
        </Typography>
      </Box>
    )
  }

  return (
    // @ts-ignore
    <Box className={classes.container}>
      <Grid className={classes.mainGrid} container spacing={1}>
        {isMobile && (
          <Button
            className={classes.careTeamButton}
            color='inherit'
            onClick={handleCareTeamBtnClick}
            variant='contained'
          >
            MEET YOUR CARE TEAM
          </Button>
        )}
        {/* @ts-ignore */}
        <Grid className={classes.imageBox} item xs={12} md={6} component={Box} display='flex' alignItems='center' justifyContent='center'>
          <Icon className={classes.iconContainer} onClick={() => setCurrentSlide(currentSlide - 1)}>
            {/* @ts-ignore */}
            <Box className={classes.iconLeft} component='img' src={ArrowLeftBlue} />
          </Icon>
          <VirtualizeSwipeableViews
            className={classes.carousel}
            index={currentSlide}
            slideRenderer={slideRenderer}
            onChangeIndex={handleChangeSlide}
          />
          <Icon className={classes.iconContainer} onClick={() => setCurrentSlide(currentSlide + 1)}>
            {/* @ts-ignore */}
            <Box className={classes.iconRight} component='img' src={ArrowRightBlue} />
          </Icon>
        </Grid>
        <Grid className={classes.featuresGrid} component={Box} item xs={12} md={6}>
          <Typography paragraph variant='h2'>
            Doctors dedicated to getting you the care you deserve.
          </Typography>
          <List dense>
            {features.map((feature) => (
              <ListItem className={classes.feature} key={feature} disableGutters>
                <ListItemIcon>
                  <Brightness1Icon className={classes.bulletIcon} color='secondary' fontSize='small' />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
          {!isMobile && (
            <Button
              className={classes.careTeamButton}
              color='inherit'
              onClick={handleCareTeamBtnClick}
              variant='contained'
            >
              MEET YOUR CARE TEAM
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  brandName: {
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: 1.334
  }
}))

type ItemProps = {
  name: string,
  brandName: string
}
export default function Item ({ name, brandName }: ItemProps) {
  const classes = useStyles()

  return (
    <Box py={2} borderRadius='60px 60px 60px 2px' bgcolor='#F5FAFF' textAlign='center'>
      <Typography className={classes.brandName} variant='body1'>{name}</Typography>
      <Typography variant='body1'>{brandName}</Typography>
    </Box>
  )
}

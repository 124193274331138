import { Link, Typography } from '@material-ui/core'
import FeatureList from 'Offerings/shared/FeatureList'
import React from 'react'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import { renewRx } from '../../offerings/constants/externalLinks'
import PrescriptionPageContainer from '../shared/PrescriptionPageContainer'

const features = [
  'Unlimited text-based visits with board-certified clinicians',
  'Doctors available day and night for Primary Care and Urgent Care',
  'Free care for your kids over 3',
  'No appointment, no insurance, no problem'
]

export default function NewPrescription () {
  return (
    <PrescriptionPageContainer>
      <Typography aria-label='Chat with a clinician to get started' aria-level={1} paragraph variant='h4' role='heading'>
        Chat with a clinician to get started
      </Typography>
      <Typography variant='body1'>
        K Health also offers:
      </Typography>

      <FeatureList features={features} />
      <ResponsiveCTAButton
        component={Link}
        context='body'
        ctaAction={'New Prescription Pricing Options Viewed'}
        href={addSegmentAnonymousIdToUrl(renewRx)}
      >
        See pricing options
      </ResponsiveCTAButton>
    </PrescriptionPageContainer>
  )
}

import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'

import { featureMap, Plans } from '.'
import TopSection from './TopSection'

export type ProductCardProps = {
  plan: Plans
}

export default function ProductCard ({ plan }: ProductCardProps) {
  return (
    <Box component={Paper} position='relative' p={3}>
      <TopSection plan={plan} />
      <Box pt={3}>
        {featureMap[plan].map((feature) => (
          <Typography gutterBottom variant='body1' key={feature}>{feature}</Typography>
        ))}
      </Box>
    </Box>
  )
}

import { Grid, makeStyles } from '@material-ui/core'
import AssessmentIcon from 'Icons/assessment.svg'
import DataPlotIcon from 'Icons/data-plot.svg'
import DoctorCrossIcon from 'Icons/doctor-cross.svg'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { selectProductPath } from 'Src/App'
import useIsMobile from 'Src/hooks/useIsMobile'
import { CtaActionType, useCTAClickTracking } from 'Src/tracking'

import HowItWorksDialog from '../HowItWorksDialog'
import DefaultOffering from './DefaultOffering'
import MobileOffering from './MobileOffering'

const useStyles = makeStyles(({ breakpoints, spacing, transitions }) => ({
  offerings: {
    maxWidth: '75%',
    [breakpoints.only('sm')]: {
      maxWidth: '80%'
    },
    [breakpoints.only('xs')]: {
      maxWidth: '95%'
    }
  }
}))

export interface OfferingProps {
  handleLinkClick: () => void;
  content: string;
  linkText: string;
  title: string;
  IconComponent: React.ReactNode;
}

export default function LearnMoreOfferings () {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useIsMobile()
  function handler (action: CtaActionType, callback: () => void): () => void {
    return () => {
      trackCTAClicked({ action, context: 'body' })
      callback()
    }
  }

  const offerings: OfferingProps[] = [
    {
      handleLinkClick: handler('How It Works Video Viewed', () => setIsDialogOpen(true)),
      content: 'Check out which program fits your needs and how to get started.',
      IconComponent: AssessmentIcon,
      linkText: 'WATCH NOW',
      title: 'How it works'
    },
    {
      handleLinkClick: handler('Membership Plans Viewed', () => history.push(selectProductPath)),
      content: 'Explore our membership plans. Get 24/7 access to doctors, low prices on prescriptions, and free care for your kids — no insurance required.',
      IconComponent: DataPlotIcon,
      linkText: 'LEARN MORE',
      title: 'Our membership'
    },
    {
      handleLinkClick: handler('Health Guides Viewed', () => { window.location.href = 'https://khealth.com/health-guides/' }),
      content: 'Access hundreds of free resources about common symptoms, conditions, medications, and treatments—all written by expert doctors.',
      IconComponent: DoctorCrossIcon,
      linkText: 'READ MORE',
      title: 'Free health guides'
    }
  ]

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const trackCTAClicked = useCTAClickTracking()

  return (
    <>
      <Grid className={classes.offerings} container spacing={4}>
        {offerings.map((offeringProps, index) => (
          <Grid item xs={12} md={4} key={`offering-${index}`}>
            {
              isMobile
                ? <MobileOffering {...offeringProps } />
                : <DefaultOffering {...offeringProps } />
            }
          </Grid>
        ))}
      </Grid>
      <HowItWorksDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
    </>
  )
}

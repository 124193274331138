import { Box, Grid, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import AppleWellnessIcon from 'Icons/apple-wellness.svg'
import HeadBrainMentalIcon from 'Icons/head-brain-mental.svg'
import HeartInHeartIcon from 'Icons/heart-in-heart.svg'
import MedicationsIcon from 'Icons/medications.svg'
import StethoscopeIcon from 'Icons/stethoscope.svg'
import {
  birthControlPath,
  chronicConditionPath,
  mentalHealthPath,
  primaryCarePath,
  urgentCarePath
} from 'Offerings/pages'
import FancyAnswerSelect from 'Questions/shared/AnswerSelect/Fancy'
import { useHistory } from 'react-router'
import { useGetUserGender } from 'Redux/userSlice'
import { useQuestionTracking } from 'Src/tracking'

import ResponsiveContainer from '../shared/ResponsiveContainer'

enum Value {
  BirthControl = 'Birth control pills',
  ChronicCondition = 'Ongoing chronic conditions',
  GeneralHealth = 'General health and wellness',
  MentalHealth = 'Mental health',
  UrgentCare = 'Urgent health issues',
}

const {
  BirthControl,
  ChronicCondition,
  GeneralHealth,
  MentalHealth,
  UrgentCare
} = Value

const birthControlOption = {
  icon: MedicationsIcon,
  subtext: 'Get affordable birth control options from a trusted clinician',
  value: BirthControl,
  dataTestId: 'birth-control-option'
}

const question = 'What would you like to chat with a doctor about?'

export default function ChatIntentExperiment () {
  const pathMap = {
    [BirthControl]: birthControlPath,
    [ChronicCondition]: chronicConditionPath,
    [GeneralHealth]: primaryCarePath,
    [MentalHealth]: mentalHealthPath,
    [UrgentCare]: urgentCarePath
  }

  const isMobile = useIsMobile()
  const isMale = useGetUserGender() === 'Male'

  const optionsProps = [
    {
      value: UrgentCare,
      icon: StethoscopeIcon,
      subtext:
        'Chat with a doctor about 100+ issues like colds, UTIs, and more',
      dataTestId: 'urgent-care-option'
    },
    {
      value: MentalHealth,
      icon: HeadBrainMentalIcon,
      subtext:
        'Connect with a provider to get anxiety and depression treatment',
      dataTestId: 'mental-health-option'
    },
    {
      value: ChronicCondition,
      icon: HeartInHeartIcon,
      subtext: 'Book an appointment to treat hypertension, diabetes, and more',
      dataTestId: 'chronic-condition-option'
    },
    ...(!isMale ? [birthControlOption] : []),
    {
      value: GeneralHealth,
      icon: AppleWellnessIcon,
      subtext:
        'Get a health checkup and discuss your health goals with a clinician',
      dataTestId: 'primary-care-option'
    }
  ]

  const options = optionsProps.map((o) => o.value)
  const trackAnswered = useQuestionTracking({ question, options })
  const history = useHistory()

  const leftColumnOptionsProps = optionsProps.filter(
    (_, index) => index % 2 === 0
  )
  const rightColumnOptionsProps = optionsProps.filter(
    (_, index) => index % 2 === 1
  )

  function handleSelect (answer: Value) {
    trackAnswered(answer)
    history.push(pathMap[answer])
  }

  return (
    <ResponsiveContainer desktopColumnSize={10}>
      <Typography
        aria-label={question}
        aria-level={1}
        variant='h4'
        align='center'
        role='heading'
      >
        {question}
      </Typography>

      {isMobile
        ? (
          optionsProps.map((option, i) => (
            <FancyAnswerSelect<Value>
              key={i}
              {...option}
              onSelect={handleSelect}
            />
          ))
        )
        : (
        // @ts-ignore
          <Grid
            container
            spacing={3}
            component={Box}
            py={3}
            justifyContent='center'
          >
            <Grid item xs={6}>
              {leftColumnOptionsProps.map((option, i) => (
                <FancyAnswerSelect<Value>
                  key={i}
                  {...option}
                  onSelect={handleSelect}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              {rightColumnOptionsProps.map((option, i) => (
                <FancyAnswerSelect<Value>
                  key={i}
                  {...option}
                  onSelect={handleSelect}
                />
              ))}
            </Grid>
          </Grid>
        )}
    </ResponsiveContainer>
  )
}

import { Box, Link, Typography } from '@material-ui/core'
import womanLookingWall from 'Images/woman-looking-next-to-wall.svg'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import { primaryCareUrl } from '../constants/externalLinks'
import DefaultPageContainer from '../shared/DefaultPageContainer'
import FeatureList from '../shared/FeatureList'

const features = [
  'Virtual doctors who prescribe birth control pills and manage your general health',
  'Smart app that reminds you about ways to stay healthy',
  '24/7 Urgent Care, anxiety and depression treatment, free care for your kids ages 3-17, and more'
]

export default function BirthControl () {
  return (
    <DefaultPageContainer image={womanLookingWall} imageAltText='Woman standing next to wall'>
      <Typography aria-label='Get a birth control prescription' aria-level={1} paragraph variant='h4' role='heading'>
        Get a birth control prescription with K Health Primary Care
      </Typography>
      <FeatureList features={features} />
      <Typography component={Box} pb={4} variant='body1'>
        <strong>No insurance required.</strong>
      </Typography>
      <ResponsiveCTAButton
        component={Link}
        context='body'
        ctaAction={'Primary Care Pricing Options Viewed'}
        href={ addSegmentAnonymousIdToUrl(primaryCareUrl) }
      >
        Get started
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

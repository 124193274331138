import { Box, Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import HipaaIcon from 'Images/hipaa-approved.svg'
import BackgroundImage from 'Images/learn-more-background.png'
import LegitscriptIcon from 'Images/legitscript-approved.svg'
import Reviews from 'Offerings/shared/Reviews'

import MedicalTeam from './MedicalTeam'
import Offerings from './Offerings/index'
import StayInTouch from './StayInTouch'

const useStyles = makeStyles(({ breakpoints, typography, spacing }) => ({
  root: {
    backgroundColor: '#00406B',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    paddingBottom: spacing(4),
    paddingTop: spacing(8),
    [breakpoints.down('sm')]: {
      backgroundImage: 'none',
      paddingTop: spacing(4)
    }
  },
  header: {
    color: '#C6F7E7',
    fontFamily: 'DharmaGothicRounded',
    fontSize: '72px',
    fontWeight: 700,
    letterSpacing: '0.2rem',
    paddingBottom: spacing(8),
    [breakpoints.down('sm')]: {
      fontSize: '52px',
      textAlign: 'center',
      padding: `0 ${spacing(2)}px ${spacing(4)}px ${spacing(2)}px`
    }
  },
  icon: {
    display: 'contents'
  }
}))

export const learnMorePath = '/learn-more'

export default function LearnMore () {
  const classes = useStyles()

  return (
    <>
      <Box role='main'>
        {/* @ts-ignore */}
        <Grid className={classes.root} bgcolor='primary.main' component={Box} container justifyContent='center' alignContent='center' alignItems='center' direction='column' item>
          <Typography className={classes.header} variant='h1'>
            EXPLORE WHAT K HAS TO OFFER
            <Box component='span' color='#FED771'>.</Box>
          </Typography>
          <Offerings />
          <Box display='flex' alignItems='center' flexDirection='row' justifyContent='center' p={4} width='100%'>
            <Icon className={classes.icon}>
              {/* @ts-ignore */}
              <Box component='img' p={2} src={LegitscriptIcon} />
            </Icon>
            <Icon className={classes.icon}>
              {/* @ts-ignore */}
              <Box component='img' p={2} src={HipaaIcon} />
            </Icon>
          </Box>
        </Grid>
        <StayInTouch />
        <MedicalTeam />
      </Box>
      {/* @ts-ignore */}
      <Grid container item component={Box} bgcolor='grey.50' justifyContent='center' pb={6} pt={6}>
        <Grid item md={8} xs={12}>
          <Reviews />
        </Grid>
      </Grid>
    </>
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

type TextProps<T> = {
  text: T,
}

// @ts-ignore
const useStyles: any = makeStyles(({ typography: { fontWeightRegular }, palette }) => ({
  root: {
    fontWeight: fontWeightRegular
  }
}))

export default function Text<T> ({ text }: TextProps<T>) {
  const classes = useStyles()

  return (
    <Typography className={classes.root} variant='button' component='p'>{text}</Typography>
  )
}

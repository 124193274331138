import { Box, makeStyles, MobileStepper } from '@material-ui/core'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { virtualize } from 'react-swipeable-views-utils'

import { reviews } from './index'
import Review from './Review'

const VirtualizeSwipeableViews: any = virtualize(SwipeableViews)

const useStyles = makeStyles(({ spacing }) => ({
  carousel: {
    padding: `0 ${spacing(6)}px`
  },
  dots: {
    justifyContent: 'center',
    backgroundColor: 'inherit'
  }
}))
export default function Carousel () {
  const [currentSlide, setCurrentSlide] = useState(0)
  const classes = useStyles()
  const numberOfSlides = reviews.length
  const slideRenderer = ({ key, index }: { key: number, index: number }) => {
    // allows for looping
    const slideIndex = mod(index, numberOfSlides)
    return (
      <Box key={key} p={1}>
        <Review key={key} {...reviews[slideIndex]} />
      </Box>
    )
  }

  function handleChangeSlide (index: number) {
    const newIndex = mod(index, numberOfSlides)
    setCurrentSlide(newIndex)
  }

  return (
    <>
      <VirtualizeSwipeableViews
        className={classes.carousel}
        slideRenderer={slideRenderer}
        onChangeIndex={handleChangeSlide}
        enableMouseEvents
      />
      <MobileStepper nextButton={false} backButton={false} className={classes.dots} position='static' variant='dots' steps={numberOfSlides} activeStep={currentSlide} />
    </>
  )
}

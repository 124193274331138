import { makeStyles, TableCell, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({ palette: { divider } }) => ({
  root: {
    border: `1px ${divider} solid`
  }
}))

type FeatureDescriptionCellProps = {
  description: string
}
export default function FeatureDescriptionCell ({ description }: FeatureDescriptionCellProps) {
  const classes = useStyles()

  return (
    <TableCell className={classes.root}>
      <Typography variant='body1'>{description}</Typography>
    </TableCell>
  )
}

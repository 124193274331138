import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { monthlyPlan, oneTimeVisit } from 'Offerings/constants/externalLinks'
import React, { useContext } from 'react'
import PricesContext from 'Src/pricesContext'
import { CtaActionType, useCTAClickTracking } from 'Src/tracking'

import { Plans } from '.'

const useStyles = makeStyles(({ palette: { common, success }, breakpoints }) => ({
  root: {
    [breakpoints.up('sm')]: {
      height: '12em'
    }
  },
  badgeText: {
    color: common.white
  }
}))

type PlanDetailsType = {
  name: string,
  price: number,
  priceDetails: string,
  externalLink: string,
  action: CtaActionType
}

type PlanMapType = {
  [key in Plans]: PlanDetailsType
}

type TopSectionProps = {
  plan: Plans
}

export default function TopSection ({ plan }: TopSectionProps) {
  const { oneTimePrice, monthlyMembershipPrice } = useContext(PricesContext)
  const { OneTime, Monthly } = Plans
  const map: PlanMapType = {
    [OneTime]: {
      name: 'One-time visit',
      price: oneTimePrice?.value,
      priceDetails: '/visit',
      externalLink: oneTimeVisit,
      action: 'OneTime Pricing Plan Selected'
    },
    [Monthly]: {
      name: 'Membership',
      price: monthlyMembershipPrice?.value,
      priceDetails: '/first month',
      externalLink: monthlyPlan,
      action: 'Monthly Pricing Plan Selected'
    }
  }
  const { name, price, priceDetails, externalLink, action } = map[plan]
  const classes = useStyles()

  const trackCTAClicked = useCTAClickTracking()

  function handleSelectPlanButtonClick () {
    trackCTAClicked({ action, context: 'body' })
  }

  return (
    // TODO: Find a better way of creating space and aligned text without setting fixed height
    <Box className={classes.root}>
      {/* @ts-ignore */}
      <Grid component={Box} container direction='column' height={1} justifyContent='space-between'>
        <Grid item>
          <Typography component='span' variant='h4'>$</Typography>
          <Typography component='span' variant='h1'>{price}</Typography>
          <Typography component='span' variant='body1'>{priceDetails}</Typography>
        </Grid>

        <Grid item>
          <Typography component={Box} pt={1} pb={2} variant='h4'>{name}</Typography>
          <Button color='secondary' variant='contained' href={externalLink} onClick={handleSelectPlanButtonClick}>Select plan</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

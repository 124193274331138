import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import React from 'react'

import Carousel from './Carousel'
import Review from './Review'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: '27px',
    fontWeight: 700
  }
}))

export const reviews = [
  { review: 'Excellent online doctors! Inexpensive and quick, meds called into the pharmacy close to my home! I love K Health!', reviewer: 'Frankie W.' },
  { review: 'Saved my life…..literally thank you. It’s easy to access, affordable, and fast.', reviewer: 'Kalee T.' },
  { review: 'I received immediate, professional help, and was able to get my prescription within a couple of hours. Thank you K Health!', reviewer: 'Bill B.' }
]

export default function Reviews () {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <div role='contentinfo'>
      {/* @ts-ignore */}
      <Box className={classes.header} px={1} pb={3} component={Typography} align='center' variant='body1'>
        See why over 6+ million people turn to K Health.
      </Box>
      {isMobile
        ? (
          <Carousel />
        )
        : (
          <Grid container spacing={4}>
            {reviews.map(({ review, reviewer }, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Review {...{ review, reviewer }} />
              </Grid>
            ))}
          </Grid>
        )}
    </div>
  )
}

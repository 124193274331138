import { Link, Typography } from '@material-ui/core'
import image from 'Images/kid-on-shoulders.svg'
import React, { useContext } from 'react'
import PricesContext from 'Src/pricesContext'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import { pediatrics } from '../../offerings/constants/externalLinks'
import DefaultPageContainer from '../shared/DefaultPageContainer'
import FeatureList from '../shared/FeatureList'

export default function Pediatrics () {
  const { monthlyMembershipPrice } = useContext(PricesContext)
  const features = [
    'Access to pediatricians from your phone, available for your questions day and night',
    'Any prescriptions your loved ones may need',
    'Care that covers you too, so you can be a healthy parent'
  ]

  return (
    <DefaultPageContainer image={image} imageAltText='Child on shoulders of parent'>
      <Typography aria-label='Protect your children’s health 24/7' aria-level={1} paragraph variant='h4' role='heading'>
      Protect your children’s health 24/7
      </Typography>
      <Typography paragraph variant='body1'>
        Chat with a clinician who specializes in the health of children ages 3+ whenever you need.
      </Typography>
      <Typography variant='body1'>
        <strong>{`For $${monthlyMembershipPrice?.value}/month, you get:`}</strong>
      </Typography>

      <FeatureList features={features} />
      <ResponsiveCTAButton
        component={Link}
        context='body'
        ctaAction='Pediatrics (3-17) Pricing Options Viewed'
        href={addSegmentAnonymousIdToUrl(pediatrics)}
      >
        Get protected
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

import { Typography } from '@material-ui/core'
// TODO: Utilize pared-down SVG when available instead of the current one that is ~10.7 MB
import manLookingAtPhoneImage from 'Images/glasses-man-looking-at-phone.png'
import { primaryCareUrl } from 'Offerings/constants/externalLinks'
import React, { useContext } from 'react'
import PricesContext from 'Src/pricesContext'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import DefaultPageContainer from '../shared/DefaultPageContainer'
import FeatureList from '../shared/FeatureList'

export default function PrimaryCare () {
  const { monthlyMembershipPrice } = useContext(PricesContext)
  const features = [
    'Clinicians who help you manage chronic conditions and prevent new ones, all from the comfort of your devices',
    '24/7 Urgent Care, prescription management, free care for your kids, and more',
    `Only $${monthlyMembershipPrice?.value}/month, billed every three months and cancellable at any time`,
    'No appointments, no insurance, no problem'
  ]
  return (
    <DefaultPageContainer image={manLookingAtPhoneImage} imageAltText='Man looking at phone'>
      <Typography
        aria-label={'Comprehensive Primary Care without the hassle'}
        aria-level={1}
        paragraph
        variant='h4'
        role='heading'
      >
        Comprehensive Primary Care without the hassle
      </Typography>
      <FeatureList features={features} />
      <ResponsiveCTAButton
        context='body'
        ctaAction='Primary Care Pricing Options Viewed'
        href={addSegmentAnonymousIdToUrl(primaryCareUrl)}
      >
        Get started
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

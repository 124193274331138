const { webAppUrl, pharmacyUrl, primaryCarePath } = window.app
export const oneTimeVisit = `${webAppUrl}/checkout/product-picker/acute`
export const monthlyPlan = `${webAppUrl}/checkout/product-picker/primary-care`
export const primaryCareUrl = `${webAppUrl}/${primaryCarePath}`
export const bhPlan = `${webAppUrl}/bh`
export const edPlan = `${webAppUrl}/questionnaire/ed`
export const transferRX = `${pharmacyUrl}/transfer-rx`
export const download = 'https://kh-i1.onelink.me/cX7T/careNav'
export const renewRx = `${webAppUrl}/checkout/product-picker/renew-rx`
export const bhAssessment = `${webAppUrl}/bh`
export const pediatrics = `${webAppUrl}/checkout/product-picker/peds`
export const healthDialogue = `${webAppUrl}/acute`

import { Box, Grid, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import AppleWellnessIcon from 'Icons/apple-wellness.svg'
import QuestionMarkIcon from 'Icons/question-mark.svg'
import SpyglassIcon from 'Icons/spy-glass.svg'
import StethoscopeIcon from 'Icons/stethoscope.svg'
import { primaryCarePath } from 'Offerings/pages'
import { agePath } from 'Questions/pages'
import FancyAnswerSelect from 'Questions/shared/AnswerSelect/Fancy'
import { learnMorePath } from 'Src/pages/LearnMore'
import { useQuestionTracking } from 'Src/tracking'

import { redirectWithSegmentParam } from '../../navigation/segment'
import { healthDialogue } from '../../offerings/constants/externalLinks'
import ResponsiveContainer from '../shared/ResponsiveContainer'

enum Value {
  CareNow = 'I need care now',
  CheckSymptoms = 'I want to check my symptoms',
  HealthCheckup = 'I want a health checkup',
  LearnMore = 'Looking for something else'
}

const { CareNow, CheckSymptoms, HealthCheckup, LearnMore } = Value

const optionsProps = [
  { value: CareNow, icon: StethoscopeIcon, subtext: 'Chat with a doctor to get treatment or a prescription refill', dataTestId: 'get-care-now-option' },
  { value: HealthCheckup, icon: AppleWellnessIcon, subtext: 'Get a health checkup and discuss your health goals with a clinician', dataTestId: 'primary-care-option' },
  { value: CheckSymptoms, icon: SpyglassIcon, subtext: 'Use our free symptom checker to see what could be wrong', dataTestId: 'symptom-checker-option' },
  { value: LearnMore, icon: QuestionMarkIcon, subtext: 'See how it works, meet the medical team, access free resources and more', dataTestId: 'learn-more-option' }
]

const options = optionsProps.map(o => o.value)
const question = 'What brings you here today?'

export default function UserIntentExperiment () {
  const pathMap = {
    [CareNow]: agePath,
    [HealthCheckup]: primaryCarePath,
    [CheckSymptoms]: healthDialogue,
    [LearnMore]: learnMorePath
  }

  const trackAnswered = useQuestionTracking({ question, options })

  const isMobile = useIsMobile()
  function handleSelect (answer: Value) {
    trackAnswered(answer)
    redirectWithSegmentParam(pathMap[answer])
  }

  return (
    <ResponsiveContainer desktopColumnSize={10}>
      <Typography aria-label={question} aria-level={1} variant='h4' align='center' role='heading'>{question}</Typography>

      {isMobile
        ? (
          optionsProps.map((option, i) => <FancyAnswerSelect<Value> key={i} {...option} onSelect={handleSelect} />)
        )
        : (
          // @ts-ignore
          <Grid container spacing={1} component={Box} py={3} justifyContent='center'>
            <Grid item sm={6}>
              {optionsProps.map((option, i) => <FancyAnswerSelect<Value> key={i} {...option} onSelect={handleSelect} />)}
            </Grid>
          </Grid>
        )}

    </ResponsiveContainer>
  )
}

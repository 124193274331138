import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { Redirect, useLocation } from 'react-router'
import RouteWithTransition from 'Src/navigation/RouteWithTransition'

import CertificationsBar from '../shared/CertificationsBar'
import Reviews from '../shared/Reviews'
import BirthControl from './BirthControl'
import ChronicCondition from './ChronicCondition'
import MentalHealth from './MentalHealth'
import AvailableMeds from './MentalHealth/AvailableMeds'
import NewPrescription from './NewPrescription'
import OverEighteen from './OverEighteen'
import Pediatrics from './Pediatrics'
import PrimaryCare from './PrimaryCare'
import SymptomChecker from './SymptomChecker'
import UnderThree from './UnderThree'
import UrgentCare from './UrgentCare'

export const birthControlPath = '/offerings/birth-control'
export const chronicConditionPath = '/offerings/chronic-condition'
export const urgentCarePath = '/offerings/urgent-care'
export const primaryCarePath = '/offerings/primary-care'
export const symptomCheckerPath = '/offerings/symptom-checker'
export const mentalHealthPath = '/offerings/mental-health'
export const newPrescriptionPath = '/offerings/new-prescription'
// export const transferPrescriptionPath = '/offerings/transfer-prescription'
export const pediatricsPath = '/offerings/pediatrics'
export const edPath = '/offerings/ed'
export const overEighteenPath = '/offerings/over-eighteen'
export const underThreePath = '/offerings/under-three'

const paths = [
  { path: birthControlPath, Component: BirthControl },
  { path: chronicConditionPath, Component: ChronicCondition },
  { path: edPath, Component: () => <Redirect to='/welcome' /> },
  { path: mentalHealthPath, Component: MentalHealth },
  { path: newPrescriptionPath, Component: NewPrescription },
  { path: pediatricsPath, Component: Pediatrics },
  { path: primaryCarePath, Component: PrimaryCare },
  { path: overEighteenPath, Component: OverEighteen },
  { path: symptomCheckerPath, Component: SymptomChecker },
  { path: underThreePath, Component: UnderThree },
  { path: urgentCarePath, Component: UrgentCare }
]

export default function Offerings () {
  const { pathname } = useLocation()
  const isMentalHealthPath = pathname === mentalHealthPath

  return (
    <>
      {paths.map(({ path, Component }) => (
        <RouteWithTransition {...{ path, Component, key: path }} />
      ))}

      <Box position='relative' mt={6}>
        <CertificationsBar />
        <Grid container direction='column'>
          {/* if more page-based customizations are added into this footer section, it will make
          sense to relocate these footer components into the pages themselves */}
          {isMentalHealthPath && (
            // @ts-ignore
            <Grid
              item
              container
              component={Box}
              bgcolor='primary.main'
              justifyContent='center'
              pt={12}
              pb={2}
            >
              <Grid item md={8} xs={10}>
                <AvailableMeds />
              </Grid>
            </Grid>
          )}
          {/* @ts-ignore */}
          <Grid
            container
            item
            component={Box}
            bgcolor='grey.50'
            justifyContent='center'
            pb={8}
            pt={isMentalHealthPath ? 4 : 12}
          >
            <Grid item md={8} xs={12}>
              <Reviews />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

import { Container } from '@material-ui/core'
import React from 'react'
import RouteWithTransition from 'Src/navigation/RouteWithTransition'

import Age from './Age'
import ChatIntent from './ChatIntent'
import Gender from './Gender'
import HelpWithMedication from './HelpWithMedication'
import UserIntent from './UserIntent'

export const agePath = '/questions/age'
export const chatIntentPath = '/questions/chat-intent'
export const childOrSelfPath = '/questions/child-or-self'
export const childsAgePath = '/questions/childs-age'
export const genderPath = '/questions/gender'
export const helpWithMedicationPath = '/questions/help-with-medication'
export const userIntentPath = '/questions/intent'

const routes = [
  { path: agePath, Component: Age },
  { path: chatIntentPath, Component: ChatIntent },
  { path: genderPath, Component: Gender },
  { path: helpWithMedicationPath, Component: HelpWithMedication },
  { path: userIntentPath, Component: UserIntent }
]

export default function Questions () {
  return (
    <Container>
      {routes.map(({ path, Component }) => (
        <RouteWithTransition {...{ path, Component, key: path }} />
      ))}
    </Container>
  )
}

import { Box, Typography } from '@material-ui/core'
import image from 'Images/woman-with-yellow-shirt.svg'
import DefaultPageContainer from 'Offerings/shared/DefaultPageContainer'
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router'

import AppleDownload from './Download/Apple'
import GoogleDownload from './Download/Google'
import SMSDownload from './Download/SMS'

interface locationState {
  state: { isUnderEighteen: true } | undefined
}

export default function SymptomChecker () {
  // only for mobile devices that are android or ios
  const shouldUseMobileDownload = isMobileOnly && (isIOS || isAndroid)
  const { state }: locationState = useLocation()
  const isUnderEighteen = state?.isUnderEighteen

  return (
    <DefaultPageContainer image={image} imageAltText='Woman with yellow shirt'>
      <Typography aria-label='Search your symptoms for free' aria-level={1} paragraph variant='h4' role='heading'>
        {isUnderEighteen
          ? (
            'Get free healthcare information with our symptom checker'
          )
          : (
            'Search your symptoms for free, the smarter way'
          )}
      </Typography>
      <Typography paragraph variant='body1'>
        {isUnderEighteen
          ? (
            'Anyone can use our Symptom Checker to get an instant, accurate idea of what could be wrong. You need to be over 18 or with a legal guardian to chat with a clinician.'
          )
          : (
            'Our app brings together data from millions of medical records to help you get a free, instant idea of what could be wrong. Plus, get access to free consultations.'
          )}
      </Typography>

      <Typography paragraph variant='body1'>
        Download the app to get started.
      </Typography>

      {shouldUseMobileDownload
        ? (
          <Box pt={2} display='flex' justifyContent='center'>
            {isIOS
              ? (
                <AppleDownload />
              )
              : (
                <GoogleDownload />
              )
            }
          </Box>
        )
        : (
          <SMSDownload />
        )}
    </DefaultPageContainer>
  )
}

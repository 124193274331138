import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './store'

type ClosedSnackBar = {
  isOpen: false,
  message: null
}

type OpenSnackbar = {
  isOpen: true,
  message: string
}

type UIState = {
  snackbar: ClosedSnackBar | OpenSnackbar
}

const initialState: UIState = {
  snackbar: {
    isOpen: false,
    message: null
  }
}

export const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    closeSnackbar: (state, action: PayloadAction) => {
      state.snackbar = { isOpen: false, message: null }
    },
    openSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar = { isOpen: true, message: action.payload }
    }
  }
})

export const { closeSnackbar, openSnackbar } = UISlice.actions
export const selectSnackbar = (state: RootState) => state.UI.snackbar
export default UISlice.reducer

import { Box, Grid, IconButton } from '@material-ui/core'
import { ReactComponent as LeftArrow } from 'Icons/arrow-left-circle.svg'
import { ReactComponent as RightArrow } from 'Icons/arrow-right-circle.svg'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { virtualize } from 'react-swipeable-views-utils'

import { meds } from './index'
import Item from './Item'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const Carousel = () => {
  const itemsPerSlide = Math.ceil(meds.length / 2)
  const slideOne = meds.slice(0, itemsPerSlide)
  const slideTwo = meds.slice(-itemsPerSlide)
  const groups = [slideOne, slideTwo]
  const numberOfSlides = groups.length
  const [currentSlide, setCurrentSlide] = useState(0)

  const slideRenderer = ({ key, index }: { key: number, index: number }) => {
    // allows for looping
    const slideIndex = mod(index, numberOfSlides)
    return (
      <Grid container direction='column' key={key} spacing={2}>
        {groups[slideIndex].map(({ name, brandName }) => (
          <Grid item key={name}>
            <Item {...{ name, brandName }} />
          </Grid>
        ))}
      </Grid>
    )
  }

  function handleOnChangeIndex (index: number) {
    setCurrentSlide(index)
  }

  return (
    <Box>
      <VirtualizeSwipeableViews enableMouseEvents index={currentSlide} onChangeIndex={handleOnChangeIndex} slideRenderer={slideRenderer} />
      <Box display='flex' justifyContent='center'>
        <IconButton onClick={() => setCurrentSlide(currentSlide - 1)}>
          <LeftArrow />
        </IconButton>
        <IconButton onClick={() => setCurrentSlide(currentSlide + 1)}>
          <RightArrow />
        </IconButton>
      </Box>
    </Box>
  )
}

export default Carousel

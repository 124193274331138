import { AppBar, Box, IconButton, Link, makeStyles, Toolbar } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useIsMobile from 'Hooks/useIsMobile'
import { ReactComponent as MobileKLogo } from 'Logos/k-logo.svg'
import kLogo from 'Logos/k-logo-with-text.svg'
import React from 'react'
import { useHistory } from 'react-router'

// @ts-ignore
const useStyles: any = makeStyles(({ palette: { common: { white } }, breakpoints, typography: { fontWeightRegular } }) => ({
  root: {
    backgroundColor: white,
    boxShadow: '0px 4px 10px 0px #3333331F'
  },
  toolbar: {
    height: '80px',
    [breakpoints.down('xs')]: {
      height: '58px'
    },
    justifyContent: 'space-between'
  },
  helpLink: {
    fontWeight: fontWeightRegular
  }
}))

// TODO: consider centralizing
const helpUrl = 'https://help.khealth.com'
const kHealthHomeUrl = 'https://khealth.com/'

export default function NavigationBar () {
  // This could be optimized to only show when the user has navigated WITHIN the app.
  const { goBack } = useHistory()

  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <AppBar elevation={0} className={classes.root} position='relative'>
      <Toolbar className={classes.toolbar}>
        {isMobile
          ? (
            <>
              <IconButton color='primary' onClick={() => goBack()}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton href={kHealthHomeUrl}>
                <MobileKLogo />
              </IconButton>
            </>
          )
          : (
            <>
              <Link href={kHealthHomeUrl}>
                {/* @ts-ignore */}
                <Box height='40px' component='img' src={kLogo} alt='K Health Logo' />
              </Link>
            </>
          )}
        <Link className={classes.helpLink} variant='button' color='textPrimary' target='_blank' rel='noreferrer' href={helpUrl}>
          {isMobile ? 'Help' : 'Need Help?'}
        </Link>
      </Toolbar>
    </AppBar>
  )
}

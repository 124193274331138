import { Button, ButtonProps } from '@material-ui/core'
import React from 'react'
import { CtaActionType, CtaContextType, useCTAClickTracking } from 'Src/tracking'

// There's probably a better way to prohibit the color prop but this works
export type CTAButtonProps<C extends React.ElementType> = ButtonProps<C, {
  color?: never,
  component?: C,
  context: CtaContextType,
  ctaAction: CtaActionType,
  dataTestId?: string
}>

export default function CTAButton<C extends React.ElementType> (
  { children, context, ctaAction, dataTestId, ...buttonProps }: CTAButtonProps<C>
) {
  const trackCTAClicked = useCTAClickTracking()

  function trackCTA () {
    trackCTAClicked({ action: ctaAction, context })
  }

  return (
    <Button
      {...buttonProps}
      onClick={trackCTA}
      variant={buttonProps.variant || 'contained'}
      color={'secondary'}
      data-testid={dataTestId}
    >
      {children}
    </Button>
  )
}

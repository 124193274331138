import { Fade } from '@material-ui/core'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

// based off of react-transition-group docs:
// https://reactcommunity.org/react-transition-group/with-react-router
type RouteWithTransitionProps = {
  path: string,
  Component: React.ComponentType
}

export default function RouteWithTransition ({ path, Component }: RouteWithTransitionProps) {
  const match = useRouteMatch(path)
  return (
    <Route exact path={path}>
      <Fade
        in={match != null}
        timeout={300}
        unmountOnExit
      >
        <div>
          <Component />
        </div>
      </Fade>
    </Route>
  )
}

import { Typography } from '@material-ui/core'
import image from 'Images/glasses-kid-with-mom.svg'
import { monthlyPlan } from 'Offerings/constants/externalLinks'
import DefaultPageContainer from 'Offerings/shared/DefaultPageContainer'
import React, { useContext } from 'react'
import PricesContext from 'Src/pricesContext'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../navigation/segment'
import FeatureList from '../shared/FeatureList'

const features = [
  'Unlimited text-based visits with board-certified clinicians for you and any of your kids over 3',
  'Personalized care plans for chronic, preventative, mental, and general health',
  'Easy medication management'
]

export default function UnderThree () {
  const { monthlyMembershipPrice } = useContext(PricesContext)
  return (
    <DefaultPageContainer image={image} imageAltText='Child with mother'>
      <Typography
        aria-label='Pediatric care is only available for kids over 3'
        aria-level={1}
        paragraph
        variant='h4'
        role='heading'
      >
        Pediatric care is only available for kids over 3
      </Typography>
      <Typography variant='body1'>
        While we don’t yet treat kids under 3, there are other ways we can help your family. A K Health membership includes:
      </Typography>

      <FeatureList features={features} />
      <ResponsiveCTAButton
        context='body'
        ctaAction='Under Three Child Pricing Options Viewed'
        href={addSegmentAnonymousIdToUrl(monthlyPlan)}
      >
        Join for $
        <span>
          {monthlyMembershipPrice?.value}
        </span>
        /month
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

import {
  Box,
  Icon,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'

import { OfferingProps } from '.'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  header: {
    color: '#146BCE',
    margin: spacing(4)
  },
  icon: {
    display: 'contents',
    maxHeight: '1rem'
  },
  linkText: {
    color: '#0070F0',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  offerings: {
    maxWidth: '75%'
  },
  offeringTitle: {
    color: '#1458A4'
  }
}))

export default function DefaultOfferings ({ content, handleLinkClick, IconComponent, linkText, title }: OfferingProps) {
  const classes = useStyles()

  const handleLinkKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { code } = e

    e.preventDefault()
    e.stopPropagation()

    if (code === 'Enter') {
      handleLinkClick()
    }
  }

  return (
    <Box bgcolor='white' display='flex' justifyContent='space-between' flexDirection='column' p={4} borderRadius='10px' height='100%'>
      <Box>
        <Icon className={classes.icon}>
          {/* @ts-ignore */}
          <Box component='img' alt={title} maxHeight='2.5rem' src={IconComponent} />
        </Icon>
        <Typography paragraph variant='h3' className={classes.offeringTitle}>
          {title}
        </Typography>
        <Typography variant='body1'>
          {content}
        </Typography>
      </Box>
      <Box pt={2}>
        <Typography className={classes.linkText} onClick={handleLinkClick} onKeyPress={handleLinkKeyPress} tabIndex={0} variant='body1'>
          <strong>{linkText}</strong>
        </Typography>
      </Box>
    </Box>
  )
}

import { Box, Button, FormControl, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import axios from 'axios'
import { useState } from 'react'

const useStyles = makeStyles(({ breakpoints, typography, spacing }) => ({
  emailInput: {
    width: '40%',
    [breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  forwardArrow: {
    cursor: 'pointer'
  },
  gridBox: {
    backgroundColor: '#E8F5FF',
    paddingBottom: spacing(8),
    paddingTop: spacing(8)
  },
  headerText: {
    fontFamily: 'DharmaGothicRounded',
    fontSize: '72px',
    fontWeight: 700,
    letterSpacing: '0.2rem',
    [breakpoints.down('sm')]: {
      fontSize: '52px',
      textAlign: 'center'
    }
  },
  infoText: {
    padding: spacing(4)
  },
  submitText: {
    paddingTop: spacing(4)
  }
}))

// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const successMessage = 'Thank you for signing up!'
const errorMessage = 'Unable subscribe to newsletter!'

export default function StayInTouch () {
  const classes = useStyles()

  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [submissionMessage, setSubmissionMessage] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const isUserSubscribed = submissionMessage === successMessage

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setUserEmail(value)
  }

  const handleSubmitEmail = async () => {
    if (emailRegex.test(userEmail)) {
      setIsEmailInvalid(false)
      setSubmissionMessage('')

      try {
        await axios.post('/subscribe-newsletter', { email: userEmail })

        setSubmissionMessage(successMessage)
      } catch (err) {
        setSubmissionMessage(errorMessage)
      }
    } else {
      setIsEmailInvalid(true)
    }
  }

  return (
    // @ts-ignore
    <Grid
      className={classes.gridBox}
      alignContent='center'
      alignItems='center'
      component={Box}
      container
      direction='column'
      item
      justifyContent='center'
    >
      <Typography className={classes.headerText} variant='h2'>
        STAY IN TOUCH
        <Box component='span' color='#146BCE'>.</Box>
      </Typography>
      <Typography className={classes.infoText} variant='body1'>
        Breaking health news and personalized care topics, delivered to your inbox.
      </Typography>
      <FormControl className={classes.emailInput}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.forwardArrow} position='end'>
                <Button aria-label='Submit email' disabled={isUserSubscribed} onClick={handleSubmitEmail}>
                  <ArrowForwardIcon />
                </Button>
              </InputAdornment>
            )
          }}
          error={isEmailInvalid}
          helperText={isEmailInvalid && 'Invalid email entered!'}
          id='Enter your email...'
          label='Enter your email...'
          onChange={handleEmailInputChange}
          value={userEmail}
        />
      </FormControl>
      {submissionMessage && (
        <Typography className={classes.submitText} variant='body1'>
          {submissionMessage}
        </Typography>
      )}
    </Grid>
  )
}

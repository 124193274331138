import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { allFeatures, featureMap, Plans } from '..'
import TopSection from '../TopSection'
import FeatureCheckCell from './FeatureCheckCell'
import FeatureDescriptionCell from './FeatureDescriptionCell'

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'unset'
  },
  border: {
    border: `1px ${theme.palette.divider} solid`
  },
  headerCell: {
    verticalAlign: 'bottom',
    textAlign: 'center'
  },
  bestValue: {
    position: 'relative'
  }
}))

type ProductGridProps = {
  plans: Plans[]
}

export default function ProductGrid ({ plans }: ProductGridProps) {
  const classes = useStyles()

  return (
    <TableContainer className={clsx(classes.border, classes.root)}>
      <Table>
        <colgroup>
          <col width='25%' />
          {plans.map((plan) => <col key={plan} width='25%' />)}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.border} />
            {plans.map((plan) => (
              <TableCell key={plan} className={clsx(classes.headerCell, classes.border)}>
                <TopSection plan={plan} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {allFeatures.map((feature) => (
            <TableRow key={feature}>
              <FeatureDescriptionCell description={feature} />
              {plans.map((plan) => {
                const hasFeature = featureMap[plan].some(f => f === feature)
                return <FeatureCheckCell key={plan} hasFeature={hasFeature} />
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

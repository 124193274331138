import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, mixins: { toolbar } }) => ({
  root: {
    height: `calc(100vh - ${toolbar.minHeight}px)`,
    paddingTop: spacing(6),
    paddingBottom: spacing(4)
  }
}))

type ResponsiveContainerProps = {
  children: React.ReactNode,
  desktopColumnSize?: number,
  role?: string,
}

export default function ResponsiveContainer ({ children, desktopColumnSize = 5, role = 'status' }: ResponsiveContainerProps) {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} justifyContent='center'>
      {/* @ts-ignore */}
      <Grid item xs={12} sm={desktopColumnSize} role={role}>
        {children}
      </Grid>
    </Grid>
  )
}

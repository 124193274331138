import { Box, makeStyles, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import React from 'react'

type ReviewProps = {
  review: string,
  reviewer: string
}

// @ts-ignore
const useStyles: any = makeStyles(({ typography: { fontWeightBold } }) => ({
  reviewer: {
    fontWeight: fontWeightBold
  },
  review: {
    color: '#333333'
  }
}))

export default function Review ({ review, reviewer }: ReviewProps) {
  const classes = useStyles()
  return (
    <Box boxShadow='0px 4px 10px 0px #3333331F' p={4} borderRadius='20px 20px 0px 20px' height='100%'>
      <Rating value={5} readOnly />
      <Typography paragraph variant='body1' className={classes.review}>
        {review}
      </Typography>
      <Typography variant='body1' className={classes.reviewer}>
        {reviewer}
      </Typography>
    </Box>
  )
}

import { Box, Icon, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

import AnswerSelect, { AnswerSelectBaseProps } from './index'
import Text from './Text'

type FancyProps<T> = {
  icon?: string,
  subtext: string,
} & AnswerSelectBaseProps<T>

// @ts-ignore
const useStyles: any = makeStyles(({ breakpoints, spacing, typography: { fontWeightRegular } }) => ({
  subtext: {
    fontWeight: fontWeightRegular,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#4D7088',
    marginTop: spacing(0.5),
    [breakpoints.up('md')]: {
      minHeight: spacing(5)
    }
  },
  icon: {
    display: 'contents'
  }
}))

export default function Fancy<T> ({ value, icon, subtext, onSelect, dataTestId }: FancyProps<T>) {
  const classes = useStyles()

  return (
    <AnswerSelect<T> dataTestId={dataTestId} value={value} onSelect={onSelect}>
      <Box display='flex'>
        {icon && (
          <Icon className={classes.icon}>
            {/* @ts-ignore */}
            <Box pr={2} component='img' alt={value} src={icon} />
          </Icon>
        )}
        <Box>
          <Text text={value} />
          <Typography className={classes.subtext}>
            {subtext}
          </Typography>
        </Box>
      </Box>
    </AnswerSelect>
  )
}

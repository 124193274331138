import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import welcomeDesktopImg from 'Images/welcome-desktop.svg'
import welcomeMobileImg from 'Images/welcome-mobile.svg'
import { userIntentPath } from 'Questions/pages'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { start, useHasStarted } from 'Redux/userSlice'
import useAppDispatch from 'Src/hooks/useAppDispatch'
import useIsMobile from 'Src/hooks/useIsMobile'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'
import { trackCareNavigationStarted } from 'Src/tracking'

const useStyles = makeStyles(({ breakpoints, typography, spacing }) => ({
  root: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  textSection: {
    [breakpoints.down('sm')]: {
      padding: spacing(2)
    }
  },
  sub: {
    [breakpoints.up('sm')]: {
      fontSize: '22px'
    },
    [breakpoints.down('xs')]: {
      ...typography.body1
    }
  }
}))

export default function Welcome () {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const hasStarted = useHasStarted()
  // const [isEnabled] = useFeatureFlagExperiment('care_nav_offer_flow', 'experiment_care_nav_flow_mar_22')

  useEffect(() => {
    function startFunnel () {
      trackCareNavigationStarted()
      dispatch(start())
    }
    if (!hasStarted) {
      startFunnel()
    }
  }, [dispatch, hasStarted])

  return (
    <Grid container className={classes.root}>
      <Grid container justifyContent='center' item xs={12} md={6} alignContent='center' role='main'>
        <Grid item xs={12} md={8} className={classes.textSection}>
          {/* <Typography aria-label='Welcome to K Health' aria-level={1} variant='h2' paragraph role='heading'> */}
          <Typography aria-label='Welcome to K Health' aria-level={1} variant='h2' role='heading'>
            Welcome to K Health.
          </Typography>
          <Typography paragraph className={classes.sub}>
            One platform that gives everyone access to affordable, quality care—whenever, wherever.
          </Typography>
          <Typography component={Box} pb={8} variant='body1'>
            Answer a few questions to see how we can help.
          </Typography>

          <ResponsiveCTAButton
            component={Link}
            context='body'
            ctaAction='Flow Started'
            dataTestId='get-started-cta-btn'
            to={userIntentPath}
          >
            Get started
          </ResponsiveCTAButton>
        </Grid>
      </Grid>
      <Grid item alt='K Health Welcome Image' xs={12} md={6} component='img' src={isMobile ? welcomeMobileImg : welcomeDesktopImg} />
    </Grid>
  )
}

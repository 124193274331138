import axios from 'axios'
import { getSegmentAnonymousId } from 'Src/navigation/segment'

const PRICE_TYPE_TO_PRICE_KEY = {
  one_time: 'oneTimePrice',
  monthly_membership: 'monthlyMembershipPrice',
  pc_membership: 'monthlyMembershipPricePC',
  mh_membership: 'monthlyMembershipPriceBH',
  medication: 'medication'
}

export const getPrices = async () => {
  const kangpyUrl = window.app.kangpyUrl
  const { data } = await axios.get(
    `${kangpyUrl}/payments/api/v3/prices/catalog`, { headers: { segment_anonymous_id: getSegmentAnonymousId() } }
  )
  const prices = {}
  // @ts-ignore
  data.forEach((price: any) => {
    // @ts-ignore
    const priceKey = PRICE_TYPE_TO_PRICE_KEY[price.type]
    let value = 0
    let prePayValue = 0
    if (priceKey) {
      const priceInterval = price?.recurring?.intervalCount || 1
      value = convertStripePriceValue(price.amount) / priceInterval

      if (price.prePayPrice?.id) {
        prePayValue = convertStripePriceValue(price.prePayPrice.amount)
      }
      // @ts-ignore
      prices[priceKey] = { value, prePayValue }
    }
  })
  return prices
}

const convertStripePriceValue = (priceValue: number): number => {
  return priceValue ? priceValue / 100 : 0
}

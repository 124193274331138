import { PhoneInput } from '@khealth/k-components'
import { Box, Button, Typography } from '@material-ui/core'
import axios from 'axios'
import { useState } from 'react'
import { useCTAClickTracking } from 'Src/tracking'

export default function SMS () {
  const [phone, setPhone] = useState('')
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const trackCTAClicked = useCTAClickTracking()

  async function sendSMS () {
    await axios.post('/send-download-sms', { phone })

    setIsLinkSubmitted(true)
    trackCTAClicked({
      action: 'SMS Download Link Sent',
      context: 'body'
    })
  }

  return (
    <Box>
      <Box pb={2}>
        <PhoneInput
          fullWidth={false}
          phone={phone}
          setPhone={(value: string) => setPhone(value)}
          onValidStatusChange={(value: boolean) => setIsValid(value)}
        />
        <Typography variant='body1'>We’ll only use this number to send the app download link to your phone</Typography>
      </Box>
      <Button
        color='secondary'
        variant='contained'
        disabled={!isValid || isLinkSubmitted}
        onClick={sendSMS}
      >
        Send download link
      </Button>
      {isLinkSubmitted && (
        <Box mt={2}>
          <Typography variant='h5'>Download link sent!</Typography>
        </Box>
      )}
    </Box>
  )
}

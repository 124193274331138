import { Dialog, DialogContent, DialogContentText, DialogTitle, Link, Tooltip, Typography } from '@material-ui/core'
import useIsMobile from 'Hooks/useIsMobile'
import AnswerSelect from 'Questions/shared/AnswerSelect'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Gender as GenderEnum, updateUserAttribute } from 'Redux/userSlice'
import useAppDispatch from 'Src/hooks/useAppDispatch'
import { useQuestionTracking } from 'Src/tracking'
import { getFormattedTestId } from 'Src/utils'

import ResponsiveContainer from '../shared/ResponsiveContainer'
import { chatIntentPath } from '.'

const options = Object.values(GenderEnum)

const question = 'What is your biological sex?'

const hintTitleText = 'Why we\'re asking'
const hintBodyText = 'We need to know your biological sex for medical purposes only, to help us provide the best care possible. We understand your gender identity may be different.'

export default function Gender () {
  const trackAnswered = useQuestionTracking({ question, options })
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isMobile = useIsMobile()

  function handleSelect (answer: GenderEnum) {
    trackAnswered(answer)
    dispatch(updateUserAttribute({
      attribute: 'gender',
      value: answer
    }))
    history.push(chatIntentPath)
  }

  return (
    <ResponsiveContainer>
      <Typography aria-label={question} aria-level={1} variant='h4' role='heading'>{question}</Typography>
      {isMobile
        ? (
          <ModalVersion />
        )
        : (
          <TooltipVersion />
        )
      }
      {options.map(o => <AnswerSelect<GenderEnum> dataTestId={getFormattedTestId(o, '-option')} key={o} onSelect={handleSelect} value={o} />)}
    </ResponsiveContainer>
  )
}

// NOTE: it might make sense to extract this out at some point
function TooltipVersion () {
  return (
    <Tooltip
      placement='right-start'
      // @ts-ignore
      title={<Typography styles={{ fontSize: '15px' }} variant='body1'>{hintBodyText}</Typography>}>
      <Typography component='span' color='secondary' variant='body1'>{hintTitleText}</Typography>
    </Tooltip>
  )
}

function ModalVersion () {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <span>
        <Link variant='body1' color='secondary' onClick={() => setShowModal(true)}>{hintTitleText}</Link>
      </span>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle disableTypography><Typography variant='h4'>{hintTitleText}</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography color='primary' variant='body1'>{hintBodyText}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

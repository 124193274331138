import { Box, Link, Typography } from '@material-ui/core'
import image from 'Images/man-with-crossed-arms.svg'
import { bhPlan } from 'Offerings/constants/externalLinks'
import DefaultPageContainer from 'Offerings/shared/DefaultPageContainer'
import FeatureList from 'Offerings/shared/FeatureList'
import { usePricesContext } from 'Src/pricesContext'
import ResponsiveCTAButton from 'Src/shared/ResponsiveCTAButton'

import { addSegmentAnonymousIdToUrl } from '../../../navigation/segment'

const features = [
  'Doctors available 24/7 for questions, concerns, and more',
  'A custom treatment plan that may include medication',
  'Unlimited text-based visits and follow-ups—no appointments needed',
  'Urgent Care, Primary Care, and more'
]

export default function MentalHealth () {
  const { monthlyMembershipPriceBH } = usePricesContext()
  return (
    <DefaultPageContainer image={image} imageAltText='Smiling man with crossed arms'>
      <Typography aria-label='Let’s manage your anxiety or depression' aria-level={1} paragraph variant='h4' role='heading'>
        Let’s manage your anxiety or depression
      </Typography>
      <Typography variant='body1'>
        Our <strong>Mental Health program</strong> includes:
      </Typography>

      <FeatureList features={features} />
      <Typography variant='body1' component={Box} pb={0} mb={0}>
        <strong>
          All for just $
          <span>
            {monthlyMembershipPriceBH?.value}
          </span> per month.
        </strong>
      </Typography>
      <Typography component={Box} pb={4} pt={0} mt={0} variant='body1'>
        No insurance required.
      </Typography>

      <ResponsiveCTAButton
        context='body'
        ctaAction='Mental Health Assessment Started'
        dataTestId='bh-qx-cta-btn'
        href={addSegmentAnonymousIdToUrl(bhPlan)}
      >
        Start free assessment
      </ResponsiveCTAButton>
      {/* @ts-ignore */}
      <ResponsiveCTAButton
        component={Link}
        ctaAction='Mental Health Landing Page Viewed'
        variant='text'
        target='_blank'
        rel='noreferrer'
        href='https://khealth.com/anxiety-depression'
      >
        Learn more
      </ResponsiveCTAButton>
    </DefaultPageContainer>
  )
}

import Offerings from 'Offerings/pages'
import Questions from 'Questions/pages'
import React, { useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useHasStarted } from 'Redux/userSlice'
import NavigationBar from 'Src/navigation/NavigationBar'
import RouteWithTransition from 'Src/navigation/RouteWithTransition'
import { setSegmentAnonymousId } from 'Src/navigation/segment'

import LearnMore, { learnMorePath } from './pages/LearnMore'
import SelectProduct from './pages/SelectProduct'
import Welcome from './pages/Welcome'
import { PricesProvider } from './pricesContext'

// TODO: should paths names live directly on their component?
export const selectProductPath = '/select-product'

const directlyNavigatablePaths = [
  '/offerings/mental-health',
  '/offerings/ed', // this url redirects back to /welcome entry point
  '/offerings/primary-care'
]

function App () {
  const { search: searchParams, pathname } = useLocation()
  const queryParams = new URLSearchParams(searchParams)
  const hasStarted = useHasStarted()
  const isDirectPath = directlyNavigatablePaths.includes(pathname)
  const shouldNavigateWelcome = !isDirectPath && !hasStarted

  const segmentAnonymousId = queryParams.get('segment_anonymous_id')
  if (segmentAnonymousId) {
    setSegmentAnonymousId(segmentAnonymousId)
    queryParams.delete('segment_anonymous_id')
  }

  useEffect(() => {
    if (hasStarted) {
      analytics.page()
    }
  }, [pathname, hasStarted])

  if (segmentAnonymousId) {
    return (
      <Redirect to={{ pathname, search: queryParams.toString() }} />
    )
  }

  return (
    <PricesProvider>
      <NavigationBar />
      {shouldNavigateWelcome && <Redirect to='/welcome' />}
      <Route exact path='/'>
        <Redirect to='/welcome' />
      </Route>
      <RouteWithTransition path='/welcome' Component={Welcome} />
      <Route path='/questions'>
        <Questions />
      </Route>
      <Route path='/offerings'>
        <Offerings />
      </Route>
      <RouteWithTransition path={selectProductPath} Component={SelectProduct} />
      <RouteWithTransition path={learnMorePath} Component={LearnMore} />
    </PricesProvider>
  )
}

export default App

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Icon,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { useState } from 'react'

import { OfferingProps } from '.'

const useStyles = makeStyles(({ breakpoints, spacing, transitions }) => ({
  expand: {
    transform: 'rotate(270deg)',
    marginLeft: 'auto',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(0deg)'
  },
  header: {
    color: '#C6F7E7',
    margin: spacing(4)
  },
  icon: {
    display: 'contents',
    [breakpoints.up('md')]: {
      maxHeight: '1rem'
    }
  },
  linkText: {
    color: '#146BCE',
    cursor: 'pointer',
    textDecoration: 'none',
    [breakpoints.down('sm')]: {
      marginTop: spacing(2)
    }
  },
  offerings: {
    maxWidth: '75%',
    [breakpoints.only('sm')]: {
      maxWidth: '80%'
    },
    [breakpoints.only('xs')]: {
      maxWidth: '95%'
    }
  },
  offeringTitle: {
    color: '#1458A4',
    [breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: spacing(1)
    }
  }
}))

export default function MobileOfferings ({ content, IconComponent, linkText, title, handleLinkClick }: OfferingProps) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Card>
      <CardActions disableSpacing onClick={() => setIsExpanded(!isExpanded)}>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          <Icon className={classes.icon}>
            {/* @ts-ignore */}
            <Box component='img' alt={title} maxHeight='2.5rem' src={IconComponent} />
          </Icon>
          <Typography paragraph variant='h4' className={classes.offeringTitle}>
            {title}
          </Typography>
        </Box>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: isExpanded
          })}
          aria-expanded={isExpanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography variant='body1'>
            {content}
          </Typography>
          <Typography className={classes.linkText} onClick={handleLinkClick} variant='body1'>
            <strong>{linkText}</strong>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

import CTAButton, { CTAButtonProps } from './CTAButton'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  }
}))

export default function ResponsiveCTAButton<C extends React.ElementType> (buttonProps: CTAButtonProps<C>) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CTAButton {...buttonProps} />
    </Box>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Redux/store'
import useAppSelector from 'Src/hooks/useAppSelector'

export enum Age {
  UnderEighteen = 'Under 18',
  EighteenToFortyFour = '18-44',
  FortyFiveToSixtyFour = '45-64',
  SixtyFiveAndOver = '65+'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

interface UserState {
  age: Age | null,
  hasStarted: boolean,
  gender: Gender | null,
  isExperimentVariant: boolean
}

interface AgePayload {
  attribute: 'age',
  value: Age
}

interface GenderPayload {
  attribute: 'gender',
  value: Gender
}

type UserPayloadAction = AgePayload | GenderPayload

const initialState: UserState = {
  age: null,
  hasStarted: false,
  gender: null,
  isExperimentVariant: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // This might make more sense in a separate status reducer
  reducers: {
    start: (state) => {
      state.hasStarted = true
    },
    enableExperimentVariant: (state) => {
      state.isExperimentVariant = true
    },
    updateUserAttribute: (state, action: PayloadAction<UserPayloadAction>) => {
      const { attribute, value } = action.payload

      // @ts-ignore
      state[attribute] = value
    }
  }
})

export const { start, updateUserAttribute, enableExperimentVariant } = userSlice.actions

export const selectGender = (state: RootState) => state.user.gender
// TODO: Dry up while retaining type specificity in return value
export const useIsExperimentVariant = () => (useAppSelector((state: RootState) => state.user.isExperimentVariant))
export const useHasStarted = () => (useAppSelector((state: RootState) => state.user.hasStarted))
export const useGetUserAge = () => (useAppSelector((state: RootState) => state.user.age))
export const useGetUserGender = () => (useAppSelector((state: RootState) => state.user.gender))

export default userSlice.reducer

import { Typography } from '@material-ui/core'
import { symptomCheckerPath } from 'Offerings/pages'
import AnswerSelect from 'Questions/shared/AnswerSelect'
import { useHistory } from 'react-router'
import { Age as AgeEnum, updateUserAttribute } from 'Redux/userSlice'
import useAppDispatch from 'Src/hooks/useAppDispatch'
import { redirectWithSegmentParam } from 'Src/navigation/segment'
import { healthDialogue } from 'Src/offerings/constants/externalLinks'
import { useQuestionTracking } from 'Src/tracking'
import { getFormattedTestId } from 'Src/utils'

import ResponsiveContainer from '../shared/ResponsiveContainer'
import { genderPath } from '.'

const question = 'How old are you?'

const options = Object.values(AgeEnum)

export default function Age () {
  const trackAnswered = useQuestionTracking({ question, options })
  const dispatch = useAppDispatch()
  const history = useHistory()

  function handleSelect (answer: AgeEnum) {
    trackAnswered(answer)
    dispatch(updateUserAttribute({
      attribute: 'age',
      value: answer
    }))

    if (answer === AgeEnum.UnderEighteen) {
      history.push(symptomCheckerPath, { isUnderEighteen: true })
    } else if (answer === AgeEnum.SixtyFiveAndOver) {
      // TODO: Update URL once HD AB test is completed
      redirectWithSegmentParam(healthDialogue)
    } else {
      history.push(genderPath)
    }
  }

  return (
    <ResponsiveContainer>
      <Typography aria-label={question} aria-level={1} paragraph variant='h4' role='heading'>{question}</Typography>
      {options.map(o => <AnswerSelect<AgeEnum> dataTestId={getFormattedTestId(o, '-option')} key={o} onSelect={handleSelect} value={o} />)}
    </ResponsiveContainer>
  )
}

import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({ breakpoints }) => {
  return ({
    container: {
      maxWidth: '70%'
    },
    root: {
      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse'
      }
    }
  })
})

type PageContainerProps = {
  image: string,
  imageAltText?: string,
  children: React.ReactNode,
  role?: string,
}

export default function PageContainer ({ children, image, imageAltText = '', role = 'main' }: PageContainerProps) {
  const classes = useStyles()

  return (
    // @ts-ignore
    <Container className={classes.container} component={Box} pt={4} pb={8}>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={6} role={role}>
          {children}
        </Grid>
        {/* @ts-ignore */}
        <Grid item xs={12} md={6} component={Box} display='flex' justifyContent='center'>
          {/* @ts-ignore */}
          <Box alt={imageAltText} component='img' src={image} width={1} />
        </Grid>
      </Grid>
    </Container>
  )
}

import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

import Text from './Text'

export type AnswerSelectBaseProps<T> = {
  dataTestId?: string,
  value: T,
  onSelect: (answer: T) => void,
}

type AnswerSelectProps<T> = {
  children?: React.ReactNode
} & AnswerSelectBaseProps<T>

const useStyles = makeStyles(({ typography: { fontWeightRegular } }) => ({
  root: {
    border: '1px solid #EEEEEE',
    padding: '25px 20px 25px 20px',
    borderRadius: '6px',
    cursor: 'pointer'
  }
}))

export default function AnswerSelect<T> ({ value, onSelect, dataTestId, children }: AnswerSelectProps<T>) {
  const classes = useStyles()

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { code } = e

    e.preventDefault()
    e.stopPropagation()

    if (code === 'Enter') {
      onSelect(value)
    }
  }

  return (
    <Box data-testid={dataTestId} onClick={() => onSelect(value)} onKeyPress={handleKeyPress} my={2} className={classes.root} tabIndex={0} role={'button'}>
      {children || <Text<T> text={value} />}
    </Box>
  )
}

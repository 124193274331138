import React, { useContext, useEffect, useState } from 'react'

import { getPrices } from './services/priceService'

type PriceValues = { value: number, prePayValue: number }

type Prices = {
  oneTimePrice: PriceValues,
  monthlyMembershipPrice: PriceValues,
  monthlyMembershipPriceBH: PriceValues,
  monthlyMembershipPricePC: PriceValues,
  medication: PriceValues,
}

export const PRICES_INIT: Prices = {
  oneTimePrice: { value: 0, prePayValue: 0 },
  monthlyMembershipPrice: { value: 0, prePayValue: 0 },
  monthlyMembershipPriceBH: { value: 0, prePayValue: 0 },
  monthlyMembershipPricePC: { value: 0, prePayValue: 0 },
  medication: { value: 0, prePayValue: 0 }
}

const PricesContext = React.createContext<Prices>({
  ...PRICES_INIT
})

export const usePricesContext = () => {
  const context = useContext(PricesContext)
  return context as Prices
}

export const PricesProvider = ({ children }: { children: any}) => {
  const [prices, setPrices] = useState<Prices>({ ...PRICES_INIT })

  useEffect(() => {
    async function fetchPrices () {
      const prices = await getPrices()
      if (prices) {
        setPrices(prices as Prices)
      }
    }
    fetchPrices()
  }, [])

  return (
    <PricesContext.Provider value={prices}>
      {children}
    </PricesContext.Provider>
  )
}

export default PricesContext
